<template>
  <div class="date-picker">
    <date-picker
      :value="value"
      type="time"
      :format="format"
      value-type="HH:mm"
      :minute-step="60"
      :lang="locale"
      :clearable="clearable"
      :disabled-date="checkDisable"
      @change="onChangeDate"
    ></date-picker>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    DatePicker,
  },
  props: {
    value: {},
    clearable: {
      type: Boolean,
      default: false,
    },
    disableType: {
      type: String,
      default: "",
    },
    format: {
      type: String,
      default: "HH:mm",
    },
  },
  computed: {
    locale() {
      if (this.$i18n.locale == "km") {
        return this.km;
      }
      return {};
    },
  },
  data() {
    return {
      km: {
        formatLocale: {
          months: [
            "មករា",
            "កុម្ភៈ",
            "មីនា",
            "មេសា",
            "ឧសភា",
            "មិថុនា",
            "កក្កដា",
            "សីហា",
            "កញ្ញា",
            "តុលា",
            "វិច្ឆិកា",
            "ធ្នូ",
          ],
          monthsShort: [
            "មករា",
            "កុម្ភៈ",
            "មីនា",
            "មេសា",
            "ឧសភា",
            "មិថុនា",
            "កក្កដា",
            "សីហា",
            "កញ្ញា",
            "តុលា",
            "វិច្ឆិកា",
            "ធ្នូ",
          ],
          weekdays: [
            "អាទិត្យ",
            "ច័ន្ទ",
            "អង្គារ",
            "ពុធ",
            "ព្រហស្បតិ៍",
            "សុក្រ",
            "សៅរ៍",
          ],
          weekdaysShort: ["អា", "ច", "អ", "ពុ", "ព្រ", "សុ", "សៅ"],
          weekdaysMin: ["អា", "ច", "អ", "ពុ", "ព្រ", "សុ", "សៅ"],
          firstDayOfWeek: 0,
          firstWeekContainsDate: 1,
        },
      },
    };
  },
  methods: {
    checkDisable(date) {
      if (this.disableType == "before") {
        return this.disabledBeforeToday(date);
      } else if (this.disableType == "after") {
        return this.disabledAfterToday(date);
      }

      return false;
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    onChangeDate(date) {
      this.$emit("input", date);
    },
  },
};
</script>